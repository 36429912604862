<template>
  <div class="device-check-container">
      <el-dialog
    :visible.sync="showInfo"
    @close="closeDialog"
    @open="openDialog"
    class="pub_dialog"
    center
  >
    <div slot="title" class="dialogHeader">
      <p class="header-title">
        <span> 调试设备 </span>
      </p>
    </div>
    <el-tabs v-model="activeName" :stretch="false" :class="{'only': isOpenPhoneCamera!=1}">
      <el-tab-pane  label="电脑摄像头检测"  name="first"  :class="'ssss'">
        <div class="detect-box">
          <DeviceCheckDialog type="restart" @closePc="closePc" ref="detectCameraPc"/>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="isOpenPhoneCamera==1" label="手机端摄像头检测" name="second">
        <div class="mobile-detect">
          <div class="Qr-Code">
            <p class="grid-title">
              <span> 手机端副摄像头二维码 </span>
            </p>
            <!-- <div class="qrcode"  ref="qrCodeUrl" id="qrcodeImg"></div> -->
            <div class="qrcode-box">
                <img src="" id="qrcodeImg" />
                <div class="step-title">
                  <!-- <p>检测步骤：</p> -->
                  <p>
                    请使用手机微信扫描上方二维码，如右侧示意图，可以查看手机实时画面，则检测通过。
                  </p>
                </div>
            </div>
          </div>
          <div class="example-img">
            <p class="grid-title">
              <span>示例</span>
            </p>
            <img src="../../../assets/images/common/exam-camera.png" />
          </div>
          <div class="camera-screen">
            <p class="grid-title">
              <span> 手机端摄像头实时画面 </span>
            </p>
            <div v-if="!phoneLiveStatus" class="video-screen ">
              <!-- 插入视频流 -->
              <div class="abnormal-tip-box">
                <img src="../../../assets/images/common/check-mobile-status.png" class="check-mobile-status" />
                <!-- <p>设备连接中，请耐心等待~</p> -->
                <!-- <p>设备断连，请重新扫码连接~</p> -->
              </div>
            </div>
              
              <div v-if="phoneLiveStatus" id="id_test_video" ref="videos" class="video-screen">
                  <!-- 插入视频流 -->
              </div>
            <div class="detect-pass">
              <p>手机摄像头实时画面检测</p>
            </div>
          </div>
        </div>
        <div @click="finishCkeck" :disabled="!phoneLiveStatus" class="check-btn-primary">完成检测</div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
  </div>
</template>
<!--<script src="https://imgcache.qq.com/open/qcloud/video/vcplayer/TcPlayer-2.3.3.js" charset="utf-8"></script>-->
<!--<script src="https://cloudcache.tencent-cloud.com/open/qcloud/video/vcplayer/TcPlayer-2.3.3.js" charset="utf-8"></script>-->
<script>
// import QRCode from "qrcodejs2";
const Q = require('q');
import $ from "jquery";
let loadedAMapJS = false // 是否加载完js
export default {
  name: "dialogCommissionEqu",
  components: {
    DeviceCheckDialog: () =>
      import("./../../exam/page/dialogs/check/DeviceCheckDialog.vue"),
  },
  props: {
    isShowDialog: {
      type: Boolean,
      default: false,
    },
    urlH5Str: {
          type: String,
          default: '',
      },
    examID: {
        type: String,
        default: '',
    },
    weixinCodeTokens: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      showInfo: false,
      activeName: "first",
      closeShowDialog: false,
      tid:null,
      phoneLiveUrl:"",
      isOpenPhoneCamera:'',//是否开启手机摄像头(1:是;0:否)
      phoneLiveStatus:'',
      player:'',
      pcLiveStatus: false,
    };
  },
  created() {
    // 判断是否加载过
    if (!loadedAMapJS) {
      this.asyncLoadJs('https://imgcache.qq.com/open/qcloud/video/vcplayer/TcPlayer-2.3.3.js').then(() => {
        loadedAMapJS = true
        console.log('动态加载css+++++++++++++')
      })
    }

  },

  mounted(){

    },
  watch: {
    isShowDialog(val) {
        if(!val){
            clearInterval(this.tid);
        }else {
            
            let params = {
                id:this.examID,
            }
            this.$api.exam.examDetailData(params).then(res=>{
                this.isOpenPhoneCamera = res.data.isOpenPhoneCamera;
                if(this.isOpenPhoneCamera==1){
                    this.$nextTick(()=>{
                        console.log(res)
                        // this.creatQrCode();
                        this.getWxCode();
                    })
                    this.tid = setInterval(() => {
                          this.checkH5Play();
                    }, 1000);
                }
            })
        }
      this.showInfo = val;
    }
  },
  methods: {
    asyncLoadJs (url) {
      return Q.Promise((resolve, reject) => {
        let hasLoaded = $('script[src="'+url+'"]').length > 0
        if (hasLoaded) {
          resolve()
          return
        }

        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        document.body.appendChild(script)
        script.onload = () => {
          resolve()
        }
        script.onerror = () => {
          reject()
        }
      })
    },
    // 检测h5播放链接
      checkH5Play(){
        let LiveParam = JSON.parse(localStorage.getItem('CheckLiveParam'));
        console.log(LiveParam,'轮询参数====')
        let params = {
          businessId: LiveParam.businessId,
          examId:LiveParam.examId,
          businessType: LiveParam.businessType || LiveParam.taskType
        }
        this.$api.exam.queryLinkStatusAndPhoneLive(params).then(res=>{
          // clearInterval(this.tid);
          // console.log(res)
          // this.initP('https://player.wdeduc.com/live/9999.flv')
          // return
          this.pcLiveStatus = res.data.pcLiveStatus;
          if(res.data.phoneLiveUrl&&res.data.phoneLiveStatus){
              clearInterval(this.tid);
              let self = this;
              setTimeout(function() {
                  self.phoneLiveStatus=res.data.phoneLiveStatus;
                  if(self.phoneLiveStatus){
                      self.phoneLiveUrl= res.data.phoneLiveUrl;
                      if(self.player){
                          self.player.destroy();
                      }
                      self.$nextTick(function (){
                          self.initP(self.phoneLiveUrl)
                      })
                  }

              }, 100)



          }
        })
      },
      initP(video) {
        let that = this;
          // if(this.player){
          //     this.player.destroy();
          // }

         this.player = new window.TcPlayer("id_test_video", {
              flv:video,
              autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
              poster: {style: "cover"},
              width :  '262',//视频的显示宽度，请尽量使用视频分辨率宽度
              height: '308',//视频的显示高度，请尽量使用视频分辨率高度
              controls: 'none',
              systemFullscreen: true,
              live: true,
              // controls:'system', // default 显示默认控件，none 不显示控件，system 移动端显示系统控件 备注：如果需要在移动端使用系统全屏，就需要设置为system。默认全屏方案是使用 Fullscreen API + 伪全屏的方式例子
              pausePosterEnabled: true,
              volume: 0.5,
              h5_flv:true,
              wording: {
                  1:'',
                  2:'手机摄像头实时画面检测',
                  4:'',
                  13:'',
                  2032: '请求视频失败，请检查网络',
                  2048: '',
                  12:'请进行手机调试'
              },
              listener: function(msg) {
                if (msg.type == 'error') {
                    that.tid = setInterval(() => {
                        that.checkH5Play();
                    }, 1000);
                }
              }
          });
          // this.autoPlay(1)

          // this.player.listener
          this.player.mute(true);
      },

      autoPlay(num) {
          if (num > 5) {
              return
          }
          if (this.player) {
              setTimeout(() => {
                  this.autoPlay(num + 1)
                  this.player.play()
                  console.log('======___player')
              }, 100)
          }
      },
      // pc 端设备检测结束
      closePc(){
          if(this.isOpenPhoneCamera==1){
              this.activeName='second';
          }else {
              this.showInfo = false;
          }
      },
    openDialog() {
      this.$nextTick(() => {
          // 修改电脑端组件样式
          if( this.$refs.detectCameraPc.$el){
              this.$refs.detectCameraPc.$el.children[0].style.background = "none";
              this.$refs.detectCameraPc.$el.style.position = "relative";
              if(this.$refs.detectCameraPc.$refs.deviceTestingCloseBtn){
                  this.$refs.detectCameraPc.$refs.deviceTestingCloseBtn.style.display =
                      "none";
              }
          }

      });
      this.closeShowDialog = true;
    },

    //   生成二维码
    // creatQrCode() {
    //   // let localDomain = JSON.parse(window.localStorage.getItem('domain')).filter(item=>item.frontend_template_id=='4')
    //   let paramURL = this.urlH5Str;
    //   if(this.$refs.qrCodeUrl.title)return
    //   new QRCode(this.$refs.qrCodeUrl, {
    //     text: paramURL, // 需要转换为二维码的内容
    //     width: 160,
    //     height: 160,
    //     colorDark: "#000000",
    //     colorLight: "#ffffff",
    //     correctLevel: QRCode.CorrectLevel.H,
    //   });
    // },
    getWxCode() {
        this.$api.exam.getWxCode(this.weixinCodeTokens).then((res) => {
          let a = 'data:image/png;base64,' + res.data.image;
          console.log(a, '1')
          document.getElementById('qrcodeImg').src = a;
        }) 
    },
    finishCkeck() {
      // 设备检测完成才能关闭弹窗
      if (this.pcLiveStatus) {
        if (this.isOpenPhoneCamera == 1 && !this.phoneLiveStatus) {
          this.$message.error('请您完成手机端摄像头检测');
        } else {
          clearInterval(this.tid);
          this.$emit("equShow", false);
        }
      } else {
        this.$message.error('请您完成电脑摄像头检测');
      }  
    },
    closeDialog() {
      clearInterval(this.tid);
      this.$emit("equShow", false);
    }
  },
};
</script>
<style lang="stylus" scoped>
.device-check-container /deep/ .el-dialog {
  border-radius: 10px;
  height: 661px;
  width: 1000px;
}
.device-check-container /deep/#tab-first{
 padding-right: 48px;
 text-align: right;
 outline: none;
 border: none;
}
.device-check-container .only /deep/#tab-first {
   padding-right: 0;
  text-align: right;
  outline: none;
  border: none;
}

.device-check-container /deep/#tab-second{
 padding-left: 48px;
 text-align: left;
 outline: none;
 border: none;
}
.device-check-container .el-tabs__nav-scroll{
//  width: 310px;
//  margin: 0 auto;
}
.device-check-container /deep/.el-tabs__active-bar{
//  width: 98px!important;
//  position: relative;
}
.device-check-container /deep/ .el-tabs__nav-wrap{
  // overflow: auto;
}

.device-check-container /deep/.el-tabs__active-bar::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  bottom: -10px;
  width: 0;
  height: 0;
  font-size: 0;
  border-width: 6px;
  border-style: solid;
  border-color: #316FFF  transparent transparent transparent;
}
.device-check-container /deep/.el-tabs__item{
 color: #999;
 line-height: 36px;
 font-size: 16px;
}

.device-check-container /deep/ .el-tabs__item.is-active {
    color: #316FFF;
}

.device-check-container /deep/ .el-tabs__item.is-active .is-top .is-focus  {
    color: #316FFF;
}

.device-check-container /deep/ .el-tabs__active-bar{
    background-color: #316FFF;
}

.device-check-container /deep/ .el-dialog__header{
    // padding:0 0 10px 0;
    padding: 0;
}

.device-check-container /deep/ .el-dialog--center .el-dialog__body{
    // padding:0 25px 30px;
    padding: 0 0 30px;

}

.device-check-container /deep/ .el-tabs__nav-scroll {
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
}
.device-check-container /deep/.el-tabs__nav-wrap::after {
  bottom: 10px;
  background: #316FFF;
  opacity: 0.2;
  height: 1px;
}

.device-check-container /deep/.el-dialog__headerbtn{
    top:10px;
}
.device-check-container /deep/.el-tabs__header {
  margin-bottom: 25px;
}

.el-dialog__header {
  padding: 0;
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
}

.el-dialog__headerbtn {
  top: 11px;
}
.check-mobile-status {
}
.device-check-container .detect-box {
  padding: 0 24px;
}
.device-check-container .check-btn-primary {
    width: 240px;
    height: 40px;
    margin: 24px auto;
    display: block;
    background: #316FFF;
    font-size: 16px;
    color: #fff;
    border-radius: 6px;
    text-align: center;
    line-height: 40px;
}

</style>
<style lang="stylus" scoped>
@import '../css/dialogCommissionEqu.styl';
@import url('https://imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.css');
</style>
